@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  @apply bg-primary w-screen overflow-x-hidden;
}

.header {
  /* background-image: url("/images/header-background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center; */
  min-height: 653px;
}

.header-cta {
  border: 1px solid var(--9710, rgba(247, 248, 248, 0.1));
  background: var(
    --05,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.05) 100%
    )
  );
}

.header-drop {
  filter: drop-shadow(0px 0px 40px rgba(0, 137, 214, 0.5));
}

.content-section {
  background-image: url("/public/images/background.png");
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -300px;
  background-size: 1440px;
}

@media only screen and (max-width: 1440px) {
  .content-section {
    background-size: cover !important;
  }
}

.built-differently-section {
  max-width: 1250px;
  @apply lg:mt-0 mx-auto lg:h-auto pt-2 lg:pt-12;
}

.different-points {
  content: "";
  background-image: url("/public/images/tick.svg");
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  @apply pl-8;
}

.institution-section {
  height: 700px;
  @apply mt-12;
}

.institution-section::before {
  content: "";
  background-image: url("/public/images/institution-section-vector.svg");
  position: absolute;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  @apply right-[-50px] lg:right-[-150px] w-[70%] lg:w-[35%] lg:top-[-200px] top-[-80px];
}

.treasuries-section {
  height: 727px;
  @apply lg:pt-12 pt-20 relative;
}

.treasuries-section::before {
  content: "";
  /*background-image: url('/public/images/treasuries-star.svg');*/
  background-image: url("/public/images/treasuries-section-vector.svg");
  position: absolute;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  @apply left-0 lg:left-[-150px] w-[70%] lg:w-[35%] lg:top-[-220px];
}

.explore-section {
  height: 813px;
  max-width: 1183px;
  @apply lg:pt-12 pt-28 mx-auto;
}

/* .built-section-card{

} */

/*.explore-content {*/
/*  border-left: 1px dashed rgba(247, 248, 248, 0.15);*/
/*  @apply pl-6;*/
/*}*/

.explore-content::before {
  content: "";
  position: absolute;
  top: 0;
  height: 72px;
  /*background: linear-gradient(142deg, #0068ec 0%, #00bbf9 100%);*/
  left: -2px;
  width: 3px;
}

.security-section {
  height: auto;
  margin-top: 100px;
  max-width: 1204px;
  @apply mx-auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .security-section {
    flex-direction: column;
    padding-top: 100px;
    align-items: center;
    justify-content: center;
  }

  .security-section .flex.items-center {
    flex-direction: column;
  }

  .security-section .security-card-gradient {
    margin-bottom: 20px;
  }
}

.security-container::before {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  top: -100px;
}

.security-card-gradient {
  border: 0.918px solid rgba(247, 248, 248, 0.1);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  width: 100%;
  max-width: 367px;
}

.gradient-button {
  border: 1px solid var(--9710, rgba(247, 248, 248, 0.1));
  background: var(
    --05,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.05) 100%
    )
  );
}

.investors-section {
  height: 560px;
  margin-top: 200px;
  padding-bottom: 168px;
}

@media (max-width: 768px) {
  .investors-section {
    padding-bottom: 84px;
    @apply lg:mt-[100px] mt-[50px];
  }
}

.institutions-list {
  max-width: 852px;
  @apply mt-20 lg:mt-[136px];
}

.treasuries-list {
  max-width: 852px;
  @apply mt-20 lg:mt-[136px];
}

.institution-card {
  border: 0.918px solid var(--9710, rgba(247, 248, 248, 0.1));
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  width: 401px;
}

.ic-card-container {
  max-width: 1251px;
  @apply mx-auto;
}

.heading {
  @apply text-[45px] leading-[59px] text-white-snow;
}

footer {
  background: rgba(255, 255, 255, 0.07);
}

.content {
  @apply text-secondary-text text-[18px] leading-[28px];
}

.gradient-card {
  border: 0.918px solid var(--9710, rgba(247, 248, 248, 0.1));
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(53, 41, 41, 0.1) 100%
  );
}

@media only screen and (max-width: 320px) {
  .newTradesData {
    max-height: 200px;
  }
}

@media only screen and (max-width: 320px) {
  .carousel {
    font-size: 5px !important;
    font-weight: lighter !important;
  }
}

@media only screen and (min-width: 350px) and (max-width: 424px) {
  .carousel {
    font-size: 6px !important;
    font-weight: lighter !important;
    margin-left: 5px;
  }
}

.contact-us-section {
  @apply mt-2;
}

.contact-us-section::before {
  content: "";
  background-image: url("/public/images/institution-section-vector.svg");
  position: fixed;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  @apply right-[-50px] lg:right-[-150px] w-[70%] lg:w-[35%] lg:top-[-200px] top-[-80px];
}

.contact-us-page {
  background-image: url("/public/images/background.png");
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -300px;
  background-size: 1440px;
}
